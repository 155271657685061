.loading {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  opacity: 1;
  z-index: 9999;
  -webkit-transition: all 1.5s 2s ease-in-out;
  -moz-transition: all 1.5s 2s ease-in-out;
  -ms-transition: all 1.5s 2s ease-in-out;
  -o-transition: all 1.5s 2s ease-in-out;
  transition: all 1.5s 2s ease-in-out;

  .loading-wrapper {
    position: relative;
    left: calc(50% - 25px);
    top: calc(50% - 20px);
    width: 50px;
    height: 40px;
    -webkit-transition: all 2s 1s ease-in-out;
    -moz-transition: all 2s 1s ease-in-out;
    -ms-transition: all 2s 1s ease-in-out;
    -o-transition: all 2s 1s ease-in-out;
    transition: all 2s 1s ease-in-out;

    .rec {
      float: left;
      height: 100%;
      width: 6px;
      margin-right: 3px;
      background: #fff;
      -webkit-animation: loading 1.2s infinite ease-in-out;
      -moz-animation: loading 1.2s infinite ease-in-out;
      -ms-animation: loading 1.2s infinite ease-in-out;
      -o-animation: loading 1.2s infinite ease-in-out;
      animation: loading 1.2s infinite ease-in-out;

      &:last-child {
        margin-right: 0px; } }

    .r2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s; }

    .r3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s; }

    .r4 {
      -webkit-animation-delay:  -.9s;
      animation-delay:  -.9s; }

    .r5 {
      -webkit-animation-delay: -.8s;
      animation-delay: -.8s; } } }

@-webkit-keyframes loading {
  0%,100%,40% {
    -webkit-transform: scaleY(.5);
    -moz-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    -o-transform: scaleY(.5);
    transform: scaleY(.5); }

  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }


@-moz-keyframes loading {
  0%,100%,40% {
    -webkit-transform: scaleY(.5);
    -moz-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    -o-transform: scaleY(.5);
    transform: scaleY(.5); }

  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }


@-ms-keyframes loading {
  0%,100%,40% {
    -webkit-transform: scaleY(.5);
    -moz-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    -o-transform: scaleY(.5);
    transform: scaleY(.5); }

  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }



@-o-keyframes loading {
  0%,100%,40% {
    -webkit-transform: scaleY(.5);
    -moz-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    -o-transform: scaleY(.5);
    transform: scaleY(.5); }

  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }


@keyframes loading {
  0%,100%,40% {
    -webkit-transform: scaleY(.5);
    -moz-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    -o-transform: scaleY(.5);
    transform: scaleY(.5); }

  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }


.loading {
  &.loaded {
    visibility: hidden;
    opacity: 0; } }

.loaded {
  .loading-wrapper {
    opacity: 0; } }
